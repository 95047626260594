const DASHBOARD_SEARCH_BOX = {

	getChartTypes() {
		return [
			{ id: '707', type: '1', name: '차트' },
			{ id: '708', type: '2', name: '테이블' },
		]
	},
	
	getWidgetTypes() {
		return [
			{ id: '712', type: 'line', name: '꺽은선', parent: '707' }, 
			{ id: '713', type: 'bar', name: '세로 막대', parent: '707' }, 
			{ id: '714', type: 'pie', name: '파이차트', parent: '707' },
			{ id: '715', type: 'table', name: '표 테이블', parent: '708'}, 
			{ id: '716', type: 'pivot', name: '피벗 테이블', parent: '708' },
			{ id: '1166', type: 'fullstackedbar', name: '100% 기준 누적 세로 막대', parent: '707' },
			{ id: '1167', type: 'fullstackedrotatedbar', name: '100% 기준 누적 가로 막대', parent: '707' },
			{ id: '1168', type: 'stackedbar', name: '누적 세로 막대', parent: '707' },
			{ id: '1169', type: 'stackedrotatedbar', name: '누적 가로 막대', parent: '707' },
			{ id: '1170', type: 'rotatedbar', name: '가로 막대', parent: '707' },
			{ id: '1205', type: 'treemap', name: '트리맵', parent: '707' },
			{ id: '1229', type: 'scoreguard', name: '스코어카드', parent: '707' },
		];
	},
	
	getDateTypes() {
		return [
			{ id: '210', type: 'daily', name: '일별' }, 
			// { id: '211', type: 'monthly', name: '월별' }, 
			// { id: '212', type: 'year', name: '년별' }, 
			{ id: '213', type: 'hour', name: '시간별' },
		];
	},

	getSearchTypes() {
		return [
			//{ id: '1128', type: '2', name: '고정 기간 조회' }, 
			// { id: '1129', type: '1', name: '누적 기간 조회' }, 
			{ id: '1130', type: '0', name: '최근조회' },
		];
	},
	
};

export { DASHBOARD_SEARCH_BOX };
