<!--
  PACKAGE_NAME : src\pages\report\config\dash-board.vue
  FILE_NAME : list
  AUTHOR : mwkang
  DATE : 2024-05-27
  DESCRIPTION : 위젯 팀플릿 관리 화면
-->
<template>
	<div class="content-themes">
		<div class="page-sub-box">
			<esp-dx-data-grid :data-grid="dataGrid" ref="widgetTemplateGrid"></esp-dx-data-grid>
		</div>
	</div>
</template>

<script>
import { isSuccess, formatDate } from '@/plugins/common-lib';
import { DASHBOARD_SEARCH_BOX } from '@/pages/report/dashboard';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

let vm = this;
export default {
	components: {EspDxDataGrid},
	props: {
		codeKey: {
			type: String,
			default: '',
		},
	},
	watch: {},
	data() {
		return {
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					viewCd: null,
				},
				paramsData: null,
			},
			page: {
				sizeList: [],
				size: null,
			},
			pageData: {
				dateTypes: DASHBOARD_SEARCH_BOX.getDateTypes(),
				chartTypes: DASHBOARD_SEARCH_BOX.getChartTypes(),
				widgetTypes: DASHBOARD_SEARCH_BOX.getWidgetTypes(),
			},
			dataGrid: {
				refName: 'widgetTemplateGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				repaintChangesOnly: false,
				hoverStateEnabled: true,
				keyExpr: 'id',
				dataSource: [],
				//height: 'calc(100vh - 270px)', // 주석처리시 100%
				apiActionNm: {
					//delete: 'WIDGETTP_LIST_DELETE',
				},
				showActionButtons: {
					// select: true,
					// copy: true,
					// delete: true,
					customButtons: [
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '추가',
								elementAttr: { class: 'btn_XS default filled add1' },
								width: 60,
								height: 30,
								onClick() {
									vm.onAddData();
								},
							},
							location: 'before',
						},
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '삭제',
								elementAttr: { class: 'btn_XS white light_filled trash' },
								width: 60,
								height: 30,
								onClick() {
									vm.onDeleteData();
								},
							},
							location: 'before',
						},
					],
				},
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: true,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				sorting: {
					mode: 'multiple', // single multiple
				},
				// scrolling:{ // 미사용시 주석처리
				//     mode: 'infinite' //스크롤 모드 : ['infinite', 'standard', 'virtual']
				// },
				remoteOperations: {
					// 서버사이드 여부
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					// scrolling 미사용시만 적용됨
					enabled: true,
					pageSize: 10, //page.size,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: true, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [], //페이지 사이즈 선택 박스
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
				},
				headerFilter: {
					visible: true,
				},
				editing: {
					allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
					allowDeleting: false,
					allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
					changes: [], //null값으로 두면 에러남
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch'] - batch에서는 inserted 필요없음
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          allowSelectAll: true, // 전체 선택 허용 여부
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          deferred: false, //scrolling infinite 시 복수 선택 에러 방지
        },
				columns: [
					{
						caption: 'id',
						dataField: 'id',
						width: 80,
						height: 40,
						alignment: 'center', // left center right
						visible: false,
						allowEditing: false,
						sortOrder: 'none', // acs desc none
						allowHeaderFiltering: false,
						fixed: false, // 컬럼 fix 시 사용
						fixedPosition: 'left', // left or right
					},
					{
						caption: '구분',
						dataField: 'widgetShapeTypeFl',
						// width: 110,
						height: 20,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						lookup: {
							dataSource: () => this.pageData.chartTypes,
							displayExpr: 'name',
							valueExpr: 'id',
						},
					},
					{
						caption: '위젯 유형',
						dataField: 'chartTypeCd',
						// width: 110,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						lookup: {
							dataSource: () => this.pageData.widgetTypes,
							displayExpr: 'name',
							valueExpr: 'id',
						},
					},
					{
						caption: '위젯명',
						dataField: 'title',
						width: 400,
						height: 40,
						alignment: 'left',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						cellTemplate: (container, options) => {
							let aTag = document.createElement('a');
							aTag.innerText = options.value;
							aTag.addEventListener('click', () => {
								// let otherColumnDataField = 'id'; // 다른 컬럼의 dataField 값
								// let otherColumnValue = options.data;

								this.onShowCdData(options.data);
							});
							container.append(aTag);
						},
					},
					{
						caption: '날짜/시간 유형',
						dataField: 'dateGroupCode',
						// width: 130,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						allowSorting: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						lookup: {
							dataSource: () => this.pageData.dateTypes,
							displayExpr: 'name',
							valueExpr: 'id',
						},
					},
					// {
					// 	caption: '조회 기간',
					// 	dataField: 'searchConditionValue',
					// 	// width: 150,
					// 	height: 40,
					// 	alignment: 'center',
					// 	visible: true,
					// 	allowEditing: true,
					// 	sortOrder: 'none',
					// 	allowHeaderFiltering: false,
					// },
					{
						caption: '최근 수정자',
						dataField: 'editId',
						// width: 120,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						requiredRule: {
							message: '필수값 입니다.',
						},
					},
					{
						caption: '최근 수정일시',
						dataField: 'editDt',
						// width: 120,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						calculateCellValue: row => this.convertDateFormat(row?.editDt),
					},
					{
						caption: '등록자',
						dataField: 'regId',
						// width: 120,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
					},
					{
						caption: '등록일시',
						dataField: 'regDt',
						// width: 120,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						calculateCellValue: row => this.convertDateFormat(row?.regDt),
					},
					// {
					// 	caption: 'SORT',
					// 	dataField: 'siteOrd',
					// 	width: 110,
					// 	height: 40,
					// 	alignment: 'center',
					// 	visible: true,
					// 	allowEditing: true,
					// 	sortOrder: 'none',
					// 	allowHeaderFiltering: false,
					// 	requiredRule: {
					// 		message: '필수값 입니다.',
					// 	},
					// },
					{
						caption: '사용여부',
						dataField: 'viewCd',
						// width: 110,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						lookup: {
							dataSource: this.$_enums.common.integerViewFlag.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
						requiredRule: {
							message: '필수값 입니다.',
						},
					},
				],
			},
		};
	},
	computed: {},
	methods: {
		convertDateFormat(date) {
			return date ? formatDate(date, 'YYYYMMDDHHmmssSSS').format('YYYY.MM.DD HH:mm:ss') : '';
		},
		onShowCdData(item) {
			console.log('item:: ', item);
			let params = {
				data: item,
				updateYn: true,
			};
			this.$store.commit('setParams', params);
			this.$router.push({ name: 8355, params: params });
		},
		onAddData(){
			let params = {
				type: 'datadashboard',
				updateYn: false,
			};
			this.$store.commit('setParams', params);
			this.$router.push({ name: 8356, params: params });
		},
		/** @description: 템플릿 삭제 메서드 */
		async onDeleteData() {

			const selectedRowsData = vm.$refs.widgetTemplateGrid.selectedRowsData;

			if (!selectedRowsData?.length) {
				this.$_Msg('대상이 선택되어 있지 않습니다.');
				return false;
			}

			let deletedIds = selectedRowsData.map(d => {return { id : d.id }})

			if( await this.$_Confirm('선택한 데이터를 삭제 하시겠습니까?') ) {
				const payload = {
					actionname: 'WIDGETTP_LIST_DELETE',
					data: { data : { data : deletedIds } } ,
					loading: false,
				}

				const res = await this.CALL_REPORT_API(payload);
				
				if (isSuccess(res)) {
					await this.selectDataList();
				} else {
					this.$_Msg('삭제에 실패 하였습니다.');
				}
				
			} 
		},
		/** @description: 데이터 조회 메서드 */
		async selectDataList(sort = '-regDt') {
			let vm = this;

			vm.dataGrid.dataSource = new CustomStore({
				key: 'id',
				async load(loadOptions) {
					let params = vm.$_getDxDataGridParam(loadOptions);

					if (!params.sort) {
						params.sort = sort;
					}

					params = { ...params, ...vm.searchType.paramsData};
					//params = { ...params };

					const payload = {
						actionname: 'WIDGETTP_LIST_ALL',
						// data: { params: params },
						data: params,
						loading: false,
						useErrorPopup: true,
					};

					const res = await vm.CALL_REPORT_API(payload);

					vm.$log.debug('payload:: ', payload);

					if (isSuccess(res)) {
						return res.data.data;
					} else {
						vm.$_Toast('서버 연결에 실패 하였습니다.');
					}

					// if (isSuccess(res)) {
					// 	let rtnData = {
					// 		data: res.data.data,
					// 		totalCount: res.data.header.totalCount,
					// 	};

					// 	vm.$refs.widgetTemplateGrid.totalCount = rtnData.totalCount;

					// 	return rtnData;
					// } else {
					// 	return () => {
					// 		throw 'Data Loading Error';
					// 	};
					// }
				},
				insert(values) {
					//return false;
				},
				update(key, values) {
					//return false;
				},
				totalCount: opts => {
					return new Promise((resolve, reject) => {
						resolve(0);
					});
				},
			});

		},
		/** @description: 표시여부 출력 */
		viewCd(rowData) {
			if (Number(rowData.viewCd) === 1) {
				return true;
			} else {
				return false;
			}
		},
		/** @description: 라이프 사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;
		},
		/** @description: 라이프 사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.selectDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	destroyed() {},
};
</script>

<style scoped>
.container {
	background-color: #fff;
	margin-bottom: 100px;
}

.container-top-wrap {
	margin: 20px 0 10px 0;
}

.search-top-wrap > div {
	display: inline-block;
}

.search-top-wrap > div:not(:last-child) {
	margin-right: 5px;
}

.search-top-wrap > div:first-child {
	margin-right: 20px;
}

.container-top-wrap .search-bottom-wrap {
	margin-top: 30px;
}

.search-bottom-wrap .search-bottom-top-wrap {
	font-size: 0.95em;
}

.search-bottom-wrap .search-bottom-bottom-wrap {
	margin-top: 5px;
}

.search-bottom-bottom-wrap {
	position: relative;
}

.search-bottom-bottom-wrap > div {
	display: inline-block;
}

.search-bottom-bottom-wrap .search-bottom-left-wrap > div {
	display: inline-block;
}

.search-bottom-bottom-wrap .search-bottom-left-wrap > div:not(:first-child) {
	margin-left: 5px;
}

.search-bottom-bottom-wrap .search-bottom-right-wrap {
	position: absolute;
	right: 0;
}

.search-bottom-bottom-wrap .search-bottom-right-wrap .batch-btn {
	height: 30px;
	padding: 0 20px;
	display: inline-block;
	border: 1px solid gray;
	border-radius: 3px;
	text-align: center;
	line-height: 28px;
	cursor: pointer;
}

.inner {
	padding: 0px 21px !important;
}
</style>

<style>
.container .dx-pager .dx-pages {
	text-align: center;
	float: none;
}

.contents-length {
	color: crimson;
}

.updated-cell {
	background-color: #5dc7f880;
}
</style>